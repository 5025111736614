<template>
  <div>
    <!-- {{ testWenZi }} -->

    <van-form @submit="save">
      <van-field v-model="model.ordererName" label="姓名" placeholder="姓名" input-align="right" :rules="[{ required: true, message: '请填写姓名' }]" />
      <van-field v-model="model.ordererPhone" label="手机号" placeholder="手机号" input-align="right" :rules="[{ required: true, message: '请填写手机号' }]" />
      <div style="margin-left: 16px;">
        <van-uploader v-model="ordererFile" :after-read="afterRead" @oversize="onOversize"></van-uploader>
      </div>
      <div style="margin-left: 16px;">
        <van-uploader v-model="driverFile" :after-read="afterRead" @oversize="onOversize" disabled></van-uploader>
      </div>
      <div style="margin: 16px;">
        <van-button round block type="info">保存</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  data() {
    return {
      model: {
        ordererName: "",
        ordererPhone: "",
        ordererPic: 0,
        driverPic: 0,
        ordererCreateTime: new Date().toISOString(),
      },
      testWenZi: "12312332",
      ordererFile: [],
      driverFile: [],
    };
  },
  methods: {
    afterRead(file) {
      let that = this;
      let fd = new FormData();
      fd.append("data", file.file);
      that.axios3.post("FileReceive/Recv", fd).then((response) => {
        if (response.data.code == 101) {
          that.$notify({ type: "success", message: "上传成功" });
          that.model.ordererPic = response.data.data.id;
        }
      });
    },
    save() {
      let that = this;
      that.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
        overlay: true,
      });
      that.axios
        .post("ERP_Orderer_Driver/CangKu_UpdateModel", that.model)
        .then((response) => {
          if (response.data.code == 101) {
            console.log(response);
            that.$toast.clear();
          }
        });
    },
    onOversize() {},
  },
  mounted() {},
};
</script>

<style></style>